<template>
  <div class="main accountType2">
    <div class="container">
      <h1>
        申請帳號<a href="javascript:void(0);" @click="$router.push('/login')"
          >登入</a
        >
      </h1>
      <div class="formBox">
         <div class="formGroup">
          <div class="formRow">
            <h3>使用者類別 *</h3>
            <label for="member_type2_1">
              <input
                v-model="User.UserType"
                type="radio"
                id="member_type2_1"
                name="member_type02"
                :value="5"
              />
              計劃委員
            </label>
            <label for="member_type2_2" v-if="false">
              <input
                v-model="User.UserType"
                type="radio"
                id="member_type2_2"
                name="member_type02"
                :value="12"
              />
              理監事
            </label>
            <label for="member_type03" v-if="false"
              ><input
                v-model="User.UserType"
                type="radio"
                name="member_type02"
                :value="11"
              />顧問</label
            >
            <label for="member_type03" v-if="false"
              ><input
                v-model="User.UserType"
                type="radio"
                name="member_type02"
                :value="14"
              />名譽理事長</label
            >
            <label for="member_type03" v-if="false"
              ><input
                v-model="User.UserType"
                type="radio"
                name="member_type02"
                :value="15"
              />理事長</label
            >
            <label for="member_type03" v-if="false"
              ><input
                v-model="User.UserType"
                type="radio"
                name="member_type02"
                :value="13"
              />副理事長</label
            >
            <label for="member_type03" v-if="false"
              ><input
                v-model="User.UserType"
                type="radio"
                name="member_type02"
                :value="10"
              />秘書長</label
            >
            <label for="member_type03" v-if="false"
              ><input
                v-model="User.UserType"
                type="radio"
                name="member_type02"
                :value="9"
              />副秘書長</label
            >
            <label for="member_type08"
              ><input
                v-model="User.UserType"
                type="radio"
                id="member_type08"
                name="member_type02"
                :value="8"
              />委員會召集人</label
            >
            <label for="member_type03" v-if="false"
              ><input
                v-model="User.UserType"
                type="radio"
                name="member_type02"
                :value="7"
              />執行秘書</label
            >
            <label for="member_type06"
              ><input
                v-model="User.UserType"
                type="radio"
                id="member_type06"
                name="member_type02"
                :value="6"
              />委員會委員</label
            >
          </div>
          <div class="formRow">
            <h3>帳號 *</h3>
            <input
              v-model="User.UserName"
              required
              id="account"
              type="text"
              name=""
              value=""
            /><span>請以身分證字號爲帳號</span>
          </div>
          <div class="formRow">
            <h3>Email *</h3>
            <input
              v-model="User.Email"
              required
              id="email"
              type="email"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>姓名 *</h3>
            <input
              v-model="User.Name"
              required
              id="name"
              type="text"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>密碼 *</h3>
            <input
              v-model="User.Password"
              required
              id="password1"
              type="password"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>確認密碼 *</h3>
            <input
              v-model="User.Password2"
              required
              id="password2"
              type="password"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>連絡電話(手機) *</h3>
            <input
              v-model="User.Mobile"
              required
              id="mobile"
              type="tel"
              name=""
              value=""
            />
          </div>
          <div class="formRow" v-if="false">
            <h3>連絡電話(宅)</h3>
            <input
              v-model="User.Telephone"
              id="phone_home"
              type="tel"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>現職</h3>
            <input
              v-model="User.Job"
              x-required="User.UserType < 4"
              id="job_title"
              type="text"
              name=""
              value=""
            /><span>(公司全名+職稱)EX:臺灣體育運動管理學會專員</span>
          </div>
          <div class="formRow">
            <h3>戶籍地址</h3>
            <input type="text" name="" value="" v-model="User.HomeAddress" />
          </div>
          <div class="formRow">
            <h3>通訊地址</h3>
            <input type="text" name="" value="" v-model="User.ContectAddress" />
          </div>
          <div class="formRow">
            <h3>Line ID</h3>
            <input type="text" name="" value="" v-model="User.LineID" />
          </div>
        </div>
        <div class="submitBtn" @click="submit">
          <a href="javascript:void(0);">送出申請</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      User: {
        UserType: [],
        UserName: "",
        Email: "",
        Name: "",
        Password: "",
        Password2: "",
        CompanyTelephone: "",
        Telephone: "",
        Mobile: "",
        Birthday: "",
      },
    };
  },
  mounted() {
    this.setBase("register");
    this.loadCustomJs();
  },
  methods: {
    async submit() {
      if (!this.validate(".formBox")) {
        return;
      }
      if (this.User.UserType.length == 0) {
        alert("請選擇會員類別");
        return;
      }
      if (this.User.Password != this.User.Password2) {
        alert("兩次密碼不一致，請重新輸入！");
        return;
      }
      try {
        this.User.UserType = [this.User.UserType];
        var result = await this.$api.register(this.User);
        alert("已送出申請，請等待審核通過。");
        this.$router.replace("/");
      } catch (e) {
        if (e.Status == 4) {
          alert("此帳號已註冊過");
        }
      }
    },
  },
};
</script>